import React, {useState, useEffect, useCallback, useMemo} from "react";
import axios from "axios";

import {Trash, BookmarkCheck, FileEarmarkPlus, FileEarmarkMinus, FileEarmarkText, SendCheck, SendSlash, ReceiptCutoff, CheckCircle, XCircle} from "react-bootstrap-icons";
import {Modal, Button} from "react-bootstrap";
import {useAlert}      from "react-alert";

import SortableTable  from "../../../components/shared/SortableTable";
import Spinner        from "../../../components/shared/Spinner";
import EmailFormatter from "../../../formatters/EmailFormatter";
import DateFormatter  from "../../../formatters/DateFormatter";
import SearchField    from "components/shared/SearchField";
import LoadingBar     from "components/shared/LoadingBar";
import PriceFormatter, {ChangeCurrency} from "../../../formatters/PriceFormatter";

import EditScreen                   from "./modals/EditScreen";
import PaymentSendReport            from "./modals/PaymentSendReport";
import PaymentsReport, {ExportType} from "./reports/PaymentsReport";

ChangeCurrency("USD");

const PaymentList = ({ userID = null }) => {
	const [payments, setPayments]                 = useState([]);
	const [loading, setLoading]                   = useState(true);
	const [modal, setModal]                       = useState(false);
	const [modalText, setModalText]               = useState("");
	const [userToDelete, setUserToDelete]         = useState(null);
	const [showEdit, setShowEdit]                 = useState(false);
	const [userToEdit, setUserToEdit]             = useState(null);
	const [search, setSearch]                     = useState("");
	const [filteredPayments, setFilteredPayments] = useState([]);
	const [showReport, setShowReport]             = useState(false);
	const [report, setReport]                     = useState(null);
	const [paying, setPaying]                     = useState(false);

	const alert = useAlert();

	const load = useCallback(() => {
		axios
			.get("/cm-zahlungens?_limit=-1")
			.then((response) => {
				if (!userID) {
					setPayments(response.data.reverse());
					setLoading(false);
				} else {
					let payments = response.data.filter((item) => {
						return item.cv_member?.id === userID;
					});
					setPayments(payments);
					setLoading(false);
				}
			})
			.catch((error) => console.log(error));
	}, [userID]);

	const markAsPayed = useCallback((payment) => {
		if (!payment)
			return;
		axios
			.put(`/cm-zahlungens/${payment.id}`, {
				payed: !payment?.payed,
			})
			.then((response) => {
				setLoading(true);
				load();
				if (response.data.payed) {
					alert.success("Rechnung wurde bezahlt!");
				} else {
					alert.info("Rechnung wurde als unbezahlt markiert!");
				}
			})
			.catch(error => alert.error(`Fehler beim Lasen der Zahlungen: ${error}`));
	}, [alert, load]);

	const headers = useMemo(() => [
		{
			label: "Nachname",
			member: "cv_member.lastName",
		},
		{
			label: "Vorname",
			member: "cv_member.firstName",
		},
		{
			label: "E-Mail",
			member: "cv_member.email",
			formatter: EmailFormatter,
		},
		{
			label      : "Preis",
			member     : null,
			sortable   : false,
			align      : "right",
			functional : ({member}) => `${member.cv_plan.Name} (${PriceFormatter(member.cv_plan.Price, member.cv_plan.currency)})`
		},
		{
			label: "Zahlart",
			member: "cv_payment_method.Name",
		},
		{
			label: "Datum",
			member: "createdAt",
			formatter: DateFormatter,
		},
		{
			label: "Zahlung",
			member: null,
			sortable: false,
			padding: "0.8rem",
			functional: ({ member }) => {
				return (
					<>
						<span title={member.sent ? "Rechnung versandt" : "Rechnungsversand ausstehend"}>
							{member.sent ?
								<SendCheck className="svg-show text-success" />
							: <SendSlash className="svg-show" /> }
						</span>
						<span title={member.payed ? "Rechnung wurde bezahlt" : "Rechnung offen"}>
								{member.payed ? 
									<CheckCircle className="svg-show text-success" />
								: <XCircle className="svg-show text-danger" /> }
						</span>
					</>
				);
			},
		},
		{
			label: "",
			member: null,
			sortable: false,
			width: "10%",
			functional: ({ member }) => {
				return (
					<>
					 	{!member.payed ?
							<button className="btn" onClick={() => markAsPayed(member)} title="Rechnung als bezahlt markieren">
								<BookmarkCheck />
							</button>
						 : null}
						<button className="btn" onClick={() => delUserPrepare(member)}>
							<Trash />
						</button>
					</>
				);
			},
		},
	], [markAsPayed]);

	/**
	 * Freetext Filter/Search
	 */
	useEffect(() => {
		if (search === "") setFilteredPayments(payments);
		else {
			setFilteredPayments(
				payments.filter((payment) => {
					const member = payment.cv_member;
					const keyword = search.toLowerCase();

					if (
						member?.firstName?.toLowerCase().indexOf(keyword) >= 0 ||
						member?.lastName?.toLowerCase().indexOf(keyword) >= 0 ||
						member?.company?.toLowerCase().indexOf(keyword) >= 0 ||
						member?.email?.toLowerCase().indexOf(keyword) >= 0 ||
						payment.cv_payment_method?.Name?.toLowerCase().indexOf(keyword) >=
							0 ||
						payment.cv_plan?.Name?.toLowerCase().indexOf(keyword) >= 0
					) {
						return payment;
					}

					return false;
				})
			);
		}
	}, [payments, search]);

	useEffect(() => {
		setFilteredPayments(payments);
	}, [payments]);

	/*** Clean Up ***/
	useEffect(() => () => null, [payments, search]);

	useEffect(() => {
		setUserToEdit("");
		load();
	}, [load]);

	/** Send all Paypal Payments per Email **/
	const sendPaymentEmails = () => {
		setPaying(true);
		axios.get(`/cm-zahlungens/send`, { timeout : 1000 * 60 * 60 }).then((response) => {
			const report = response.data;
			if (report.failed.length < 1 && report.succeeded.length < 1) {
				alert.info("Es gab keine unversendeten Rechungen, es ist nicht zu tun.");
				setPaying(false);
				return;
			}
			if (report.failed.length < 1) {
				alert.success(<>
					Es wurden {report.succeeded.length} Rechnungen erfolgreich verschickt. Sie können sich hier einen detaillierten Bericht ansehen: <button className="btn btn-outline-secondary mx-5" onClick={() => {setReport(report); setShowReport(true)}}>Bericht</button>
				</>, {
					timeout : false
				});
				load();
				setPaying(false);
				return;
			} else {
				alert.error(<>
					Es gab Probleme beim Versenden einiger Rechungen. Sie können sich hier einen detaillierten Bericht ansehen: <button className="btn btn-outline-secondary mx-5" onClick={() => {setReport(report); setShowReport(true)}}>Bericht</button>
				</>, {
					timeout : false
				});
			}
			load();
			setPaying(false);
		})
		.catch((error) => {
			console.log(error);
			alert.error("Es gab ein Problem!");
			setPaying(false);
		});
	};

	const editUser = (id, userData) => {
		axios
			.put(`/cm-zahlungens/${id}`, {
				...userData,
			})
			.then((response) => {
				console.log(response);
				setLoading(true);
				setShowEdit(false);
				load();
				alert.success("Rechnung wurde erfolgreich bearbeitet!");
			})
			.catch((error) => {
				console.log("error");
				alert.error("Es gab ein Problem!");
			});
	};

	/*** DELETE Payment ***/
	const delUserPrepare = (payment) => {
		setModalText(
			`Sind Sie sicher, dass Sie die Rechnung mit der ID ${payment?.id} löschen wollen?`
		);
		setUserToDelete(payment);
		setModal(true);
	};
	const delUser = () => {
		if (userToDelete === null) {
			setModal(false);
			return;
		}
		axios
			.delete(`/cm-zahlungens/${userToDelete?.id}`)
			.then((response) => {
				let newPayments = payments.filter((item) => {
					return item._id !== userToDelete;
				});
				setModal(false);
				setUserToDelete(null);
				setPayments(newPayments);
				load();
				alert.success("Rechnung wurde erfolgreich gelöscht!");
			})
			.catch((error) => {
				console.log(error);
				alert.error("Es gab ein Problem!");
			});
	};

	const openPdf = (exportType) => {
		let pdfPayments = [];

		switch (exportType) {
			case ExportType.PAYED:
				pdfPayments = payments.filter((payment) => payment.payed);
				break;
			case ExportType.NOT_PAYED:
				pdfPayments = payments.filter((payment) => !payment.payed);
				break;
			default:
			case ExportType.ALL:
				pdfPayments = payments;
				break;
		}
		//console.log(exportType, pdfPayments, payments.filter(payment => payment.payed));

		PaymentsReport(pdfPayments, exportType);
	};

	return (
		<>
			<header style={{ marginBottom: "2rem" }}>
				<h2 className="py-4">
					Zahlungsübersicht
					<button
						className="btn btn-success btn-sm float-right"
						onClick={sendPaymentEmails}
					>
						{/*<PersonPlusFill className="big" />*/} Alle offenen Rechnungen
						versenden
					</button>
				</h2>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
					<section>
						<div className="btn-group" role="group">
							<button	className="btn btn-outline-primary" onClick={() => openPdf(ExportType.ALL)}>
								<FileEarmarkText />
								Alle
							</button>
							<button	className="btn btn-outline-primary" onClick={() => openPdf(ExportType.PAYED)}>
								<FileEarmarkPlus />
								Bezahlt
							</button>
							<button className="btn btn-outline-primary" onClick={() => openPdf(ExportType.NOT_PAYED)}>
								<FileEarmarkMinus />
								Ausstehend
							</button>
						</div>
						<span className="mx-3">
							PDF-Report generieren
						</span>
					</section>
				</section>
			</header>
			{loading ? 
				<Spinner />
			: (
				<>
					<SortableTable headers={headers} data={filteredPayments} />
					<EditScreen
						show={showEdit}
						setShow={setShowEdit}
						currentUser={userToEdit}
						save={editUser}
					/>
					<Modal show={modal}>
						<Modal.Header closeButton>
							<Modal.Title>Löschen</Modal.Title>
						</Modal.Header>
						<Modal.Body>{modalText}</Modal.Body>
						<Modal.Footer>
							<Button
								variant="outline-danger"
								onClick={() => {
									setUserToDelete(null);
									setModal(false);
								}}
							>
								Abbrechen
							</Button>
							<Button variant="primary" onClick={delUser}>
								OK
							</Button>
						</Modal.Footer>
					</Modal>
					<PaymentSendReport report={report} show={showReport} setShow={setShowReport} />
					<Modal show={paying} onHide={() => null}>
						<Modal.Body>
							<p className="text-center">
								<ReceiptCutoff className="my-5" style={{fontSize : "4rem"}} />
							</p>
							<h4 className="text-center">Rechnungen werden versandt...</h4>
							<LoadingBar />
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
};

export default PaymentList;
