import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {Trash, Pencil, CreditCard, FileEarmarkArrowDown, InfoCircle, XCircle, CheckCircle} from "react-bootstrap-icons";

import {useAlert}      from "react-alert";
import {Modal, Button} from "react-bootstrap";

import SortableTable  from "../../../components/shared/SortableTable";
import Spinner        from "../../../components/shared/Spinner";
import SearchField    from "components/shared/SearchField";
import EmailFormatter from "../../../formatters/EmailFormatter";

import EditScreen        from "./modals/EditScreen";
import InfoScreen        from "./modals/InfoScreen";
import createAllInvoices from "../helpers/createAllInvoices";
import createInvoice     from "../helpers/createInvoice";
import MembersReport     from "./reports/MembersReport";


const MemberList = props => {
    const [users, setUsers]                   = useState([]);
    const [loading, setLoading]               = useState(true);
    const [modal, setModal]                   = useState(false);
    const [modalText, setModalText]           = useState("");
    const [userToDelete, setUserToDelete]     = useState(null);
    const [showEdit, setShowEdit]             = useState(false);
    const [showInfo, setShowInfo]             = useState(false);
    const [userToEdit, setUserToEdit]         = useState(null);
    const [filteredUsers, setFilteredUsers]   = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentPlans, setPaymentPlans]     = useState([]);
	const [payments, setPayments]             = useState({});
    const [tags, setTags]                     = useState([]);
	const [search, setSearch]                 = useState("");

    const handleModalClose                = (setter) => setter(false);
    const alert                           = useAlert();

    const headers = useMemo(() => [
        {
            label  : "Nachname",
            member : "lastName"
        },
        {
            label  : "Vorname",
            member : "firstName"
        },
        {
            label  : "Organisation",
            member : "company",
            width  : "15%"
        },
        {
            label     : "E-Mail",
            member    : "email",
            formatter : EmailFormatter
        },
        {
            label  : "Mitgliedschaft",
            member : "cv_plan.Name"
        },
        {
            label      : "Zahlung",
            member     : null,
            padding    : "0.8rem",
            sortable   : false,
            functional : ({member}) => {
                return (
					<span>
						{payments.hasOwnProperty(member.id) && payments[member.id].payed ?
							<CheckCircle className="svg-show text-success" />
						: <XCircle className="svg-show text-danger" />}
					</span>
                );
            }
        },
        {
            label: "",
            member: null,
            sortable: false,
            width: "15%",
            functional : ({member}) => {
                return (
                    <>
                        <button className="btn" onClick={() => {showMemberInfoPrepare(member)}}>
                            <InfoCircle className="svg-show" style={{margin : 0}} />
                        </button>
                        <button className="btn" onClick={() => {createInvoice(setLoading, member, alert)}} title="Einzelne Rechnung erstellen"><CreditCard /></button>
                        <button className="btn" onClick={() => {editUserPrepare(member)}} title="Mitglied bearbeiten"><Pencil /></button>
                        <button className="btn" onClick={() => {delUserPrepare(member)}} title="Mitglied löschen"><Trash /></button>
                    </>
                )
            }
        }
    ], [payments, alert]);

    const load = useCallback(() => {
		axios.get("/cm-zahlungens?_limit=-1").then(response => {
			const newPayments = {};
			for (const payment of response.data) {
				if (payment.cv_member)
					newPayments[payment.cv_member.id] = payment;
				else
					console.error(`ERROR: broken payment`, payment);
			}
			setPayments(newPayments);
		}).catch(error => alert.error(`Fehler beim Laden der Zahlungen: ${error}`));


        axios.get("/members?accepted=true&_limit=-1").then(response => {
            setUsers(response.data);
            setLoading(false);
        }).catch(error => alert.error(`Fehler beim Laden der Mitlgiederliste: ${error}`));

        axios.get("/cv-payment-methods").then(response => {
            setPaymentMethods(response.data);
        }).catch(error => alert.error(`Fehler beim Laden der Zahlungsmethoden: ${error}`));

        axios.get("/cv-plans").then(response => {
            setPaymentPlans(response.data);
        }).catch(error => alert.error(`Fehler beim Laden der Mitgliedschaftsklassen: ${error}`));

        axios.get("/cv-tags").then(response => {
            let tags = [];
            response.data.forEach((tag, index) => {
                tags.push({id: index, name: tag.Tag});
            })
            setTags(tags);
        }).catch(error => alert.error(`Fehler beim Laden der Tags: ${error}`));
    }, [alert]);

    /**
     * Freetext Filter/Search
     */
    useEffect(() => {
        if(search === "")
            setFilteredUsers(users);
        else {
            setFilteredUsers(users.filter(user => {
                const keyword = search.toLowerCase();

                if (
                    user?.firstName?.toLowerCase().indexOf(keyword)               >= 0 ||
                    user?.lastName?.toLowerCase().indexOf(keyword)                >= 0 ||
                    user?.company?.toLowerCase().indexOf(keyword)                 >= 0 ||
                    user?.email?.toLowerCase().indexOf(keyword)                   >= 0 ||
                    user?.cv_plan?.Name?.toLowerCase().indexOf(keyword)           >= 0
                ) {
                    return user;
                }

                return false;
            }));
        }
    }, [users, search]);

    useEffect(() =>  setFilteredUsers(users), [users]);

    useEffect(() => () => null, [users, search]);

    const editUserPrepare = member => {
        //const user = users.filter(user => user.id === id)[0];
        setUserToEdit(member);
        setShowEdit(true);
    }

    const editUser = async (id, userData, avatar) => {

        const data = new FormData();
        data.append('files.avatar', avatar);
        data.append('data', JSON.stringify(userData));

        await axios.put(`/members/${id}`,
            data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(response => {
                console.log(response);
                setLoading(true);
                setShowEdit(false);
                load();
                alert.success('Das Mitglied wurde erfolgreich bearbeitet!');
            })
            .catch(error=>{
                console.log(error);
            });
    }


    /*** Delete USER ***/
    const delUserPrepare = member => {
        setModalText(`Sind Sie sicher, dass Sie den Benutzer ${member?.firstName} ${member?.lastName} löschen wollen?`);
        setUserToDelete(member.id);
        setModal(true);
        console.log("delUserPrepare");
    }

    const delUser = () => {
        console.log("delUser");
        if (userToDelete === null) {
            setModal(false);
            return;
        }
        axios.delete(`/members/${userToDelete}`)
            .then(response => {
                let newUsers = users.filter(item => {
                    return item._id !== userToDelete;
                });
                setModal(false);
                setUserToDelete(null);
                setUsers(newUsers);
                alert.success('Das Mitglied wurde erfolgreich gelöscht!');
            })
            .catch(error => {
                console.error(error);
                alert.error('Es ist ein Fehler entstanden!');
            });
    }

    /*** Show Member Info Modal ***/
    const showMemberInfoPrepare = member => {
        setUserToEdit(member);
        setShowInfo(true);
    }

    useEffect(() => {
        load();
    },[load]);

    /*** Render ***/
    return(
        <>
            <header style={{marginBottom : "2rem"}}>
                <h2 className="py-4">
                    Mitgliederverzeichnis
                    <button className="btn btn-success btn-sm float-right" onClick={() => createAllInvoices(setLoading, users, alert)}>
                        <CreditCard className="big" /> Alle Rechnungen erstellen
                    </button>
                </h2>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
					<section>
						<button	className="btn btn-outline-primary" onClick={() => MembersReport(users, payments)}>
							<FileEarmarkArrowDown />
							PDF Export
						</button>
					</section>
				</section>
            </header>
			{loading ?
				<Spinner />
			: 
			<>
				<SortableTable headers={headers} data={filteredUsers}  />
				<EditScreen show={showEdit} setShow={setShowEdit} currentUser={userToEdit} callback={() => load()} paymentMethods={paymentMethods} paymentPlans={paymentPlans} allTags={tags}/>
				<InfoScreen show={showInfo} setShow={setShowInfo} currentUser={userToEdit} onHide={() => handleModalClose(setShowInfo)}/>
				<Modal show={modal} onHide={() => handleModalClose(setModal)}>
					<Modal.Header closeButton>
						<Modal.Title>Löschen</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{modalText}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-danger" onClick={() => {setUserToDelete(null); setModal(false);}}>Abbrechen</Button>
						<Button variant="primary" onClick={delUser}>OK</Button>
					</Modal.Footer>
				</Modal>
			</>
			}
        </>
    );
};

export default MemberList;
